import React, {useState, useEffect, useContext} from 'react';
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import { Select, Radio, Pagination  } from 'antd';
import Swal from 'sweetalert2'
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp';
import DataTableCompMovil from '../../produccion/autoriaLotes/dataTableCompMovil'
import moment from 'moment';
import AuthContext from '../../../contexts/auth/AuthContext';

const { Option } = Select;

const Index =(props) =>{
    let datat = {
        titulo: "Mercar"
    }
    let paramsMercar = props.match.params
    const { user } = useContext(AuthContext)
    
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    const [listLotesB, setListLotesB] = useState([]) //LISTA LOTES
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPageMercar, setCurrentPageMercar] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [listLotesBodega, setListLotesBodega] = useState([]) //LISTA LOTES BODEGA
    const [listTiendas, setListTiendas] = useState([]) //LISTA TIENDAS
    const [listTiendasB, setListTiendasB] = useState([]) //LISTA TIENDAS
    const [listOrdersTV, setListOrdersTV ] = useState([])
    const [datosAcordeon, setDatosAcordeon] = useState({ ref:[], bodega:[]}) // DATOS ACORDEON
    const [lote, setLote] = useState('')
    const [buscar, setBuscar] = useState({
        predist:'',
        bodega:''
    }) // INPUT BUSCAR
    const [time, setTime] =useState('') //MODIFICAR TIME
    const [show, setShow] = useState(false) //SHOWINPUTSEACRH
    const [showInfoBodega, setShowInfoBodega] = useState(false)
    const [showOrders, setShowOrders] = useState(false) /// SHOW INFO VIRTUAL STORE
    const [ showlotesVS, setShowLotesVS ] = useState(false)
    const [ seeOrderList, setSeeOrderList ] = useState(false) // CHANGE VIEW VIRTUAL STORE ORDERS AND LOTS
    const [loadGeneral, setLoadGeneral] = useState(false) //CARGA GENERAL 
    const [loadGeneralBodega, setLoadGeneralBodega] = useState(false) //CARGA GENERAL BODEGA
    const [pestana, setpestana] = useState('predistribucion') //PESTAÑA ABIERTA
    const [countresults, setCountResults] = useState({
        predist: null,
        bodega: null,
    })
    const [nameCedi, setNameCedi ]=useState({
        codigo:'',
        nombre:''
    })
    const [currentPage, setCurrentPage] = useState(
        {
            predist: 1,
            bodega: 1,
         });
    const [registroTotal, setRegistroTotal] = useState({
        predist: null,
        bodega: null
    })
    const [value, setValue] = useState({predist:'todas', bodega:'todas'}) // VALUE RADIOBUTTONS
    const [datosPagination, setDatosPagination]= useState(null)
    const [detallesBonos, setDetallesBonos]= useState([])
    const [showBonos, setShowBonos]= useState(false)
    const [codigo, setCodigo] = useState('')
    const [datosLotes, setDatosLotes]=useState({
        lote:'',
        tienda:''
    })
    const [datosLotesB, setDatosLotesB]=useState({
        lote:'',
        tienda:''
    })
    const [datosLotesBodega, setDatosLotesBodega]=useState({
        lote:'',
        tienda:''
    })
    const [ virtualStoreLote, setVirtualStoreLote ] = useState([])
    const [ orderSelected, setOrderSelected] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false)
    const [ detailsLots, setDetsailLots ] = useState([])
    const [ loteSelectedTV, setLotSelectTV ] =useState([])
    const [ showDetailsLots, setShowDetailsLots ] = useState(false)
    
    useEffect(() => {
        if (user.centro_costos && !user.centro_costos.toUpperCase().includes("AREA BODEGA")) {
            listaLotes();
        }
    }, [user.nombre_cargo]);

    useEffect(()=>{
       if(paramsMercar && paramsMercar.tienda === 'b'){
        setpestana('bodega')
       }
       if(paramsMercar && paramsMercar.tienda === 'tv'){
        setpestana('tiendaVirtual')
        setLotSelectTV(paramsMercar.lote)
        getLotesVS()
        onChangeVS(paramsMercar.lote)
        setSeeOrderList(true)
       } 
    },[])
    
    useEffect(()=>{
        
    },[datosLotes, buscar, datosLotesB])

    useEffect(()=>{
        
    },[nameCedi])

    useEffect(()=>{
        
    },[buscar.bodega])
    
    const listaLotes = async(val)=>{
        let res2 = ''
        try {
            setSp(true)
            res2 = await Call('GET', '/api/mercar/cedi/lotes', true, null, false)

            setListTiendas(res2.data.data)
            setNameCedi({
                codigo: res2.data.user_cedi.codigo,
                nombre: res2.data.user_cedi.nombre
            })
        } catch (error) {
            setSp(false)
        }
       
        setSp(false)
        
        if(res2.data?.data.length > 0){
            setLoadGeneral(true)
        }else{
            setLoadGeneral(false)
        }
       
        if(paramsMercar.lote !== undefined){
         
            if((paramsMercar.tienda !== 'b') && (paramsMercar.tienda !== 'tv')){
               
                setpestana('predistribucion')
                
                let result = res2.data.data.filter(word => word.lote === parseInt(paramsMercar.lote))
                
                if(result.length > 0 ){
                    datosLotes.lote=parseInt(paramsMercar.lote)
                    
                    if(val === 'val'){
                        
                        window.history.pushState(null, "", "/bodega/mercar/"+parseInt(paramsMercar.lote))
                        datosLotes.lote=parseInt(paramsMercar.lote)
                        
                        setShow(false)
                        setSp(false)
                    }else{
                        
                        datosLotes.lote=parseInt(paramsMercar.lote)
                        
                        let b= JSON.parse(localStorage.getItem('datos'))
                                                
                        setDatosLotes({
                            lote: paramsMercar.lote,
                            tienda: paramsMercar.tienda
                        })
                        setNameCedi({
                            codigo: res2.data.user_cedi.codigo,
                            nombre: res2.data.user_cedi.nombre
                        })
                        nameCedi.codigo= res2.data.user_cedi.codigo
                        nameCedi.nombre= res2.data.user_cedi.nombre
                        setBuscar({...buscar,
                            predist: b.buscar
                        })
                        searchInfo(paramsMercar.lote, b )
                        setValue({...value, predist:b.filtro})
                        setShow(false)
                    }
                }else{
                    
                    if(val === 'val'){
                        window.history.pushState(null, "", "/bodega/mercar/")
                        setSp(false)
                    }
                    setDatosLotes({
                        lote:'',
                        tienda: ''
                    })
                    
                }
                setShow(false)
            }else if(paramsMercar.tienda === 'b'){
                
                lotesBodega()
                // setSp(false)
            }else{
                setSp(false)
            }
        }else{
            setSp(false)
        }
        
    }
    const lotesBonos =async()=>{
        setSp(true)
        const res = await Call('GET', '/api/bonos/lotes', true, null, false)
        setListLotesB(res.data.data.reverse())
        setSp(false)
    }
    const onChange = (e,a)=>{
        
        if(a.id === 'bonos'){
            setCodigo('')
            setDatosLotesB({
                ...datosLotesB,
                [a.name]: a.value,
            })


            if(a.name === 'tienda'){
                localStorage.removeItem('datos')
                localStorage.removeItem('datos2')
                setDatosLotesB({
                 ...datosLotesB,
                 tienda: a.value,
                })

                datosBonos(a.value)
                
            }else{
                listarTiendas('bonos', a.value)
                setDatosLotesB({
                    ...datosLotesB,
                    [a.name]: a.value,
                    tienda: ''
                })
                setShowBonos(false)
                setCodigo('')
            }
            
        }else if(a.id === 'bodega'){
            //BODEGA
          
            setDatosLotesBodega({
                ...datosLotesBodega,
                [a.name]: a.value,
            })
            
            currentPage.bodega=1
            if(a.name === 'lote'){
                
                localStorage.removeItem('datos')
                localStorage.removeItem('datos2')
                setDatosLotesBodega({
                    ...datosLotesBodega,
                    lote:a.value,
                })                
                datosLotesBodega.lote=a.value
                // buscar.bodega=''
                
                // si hay cambios y hay parametros, se eliminan
                // window.history.pushState(null, "", "/bodega/mercar/");
                paramsMercar.lote= undefined
                paramsMercar.tienda= undefined
                searchInfoBodega('bodega',a.value)
                setDatosAcordeon({
                    ...datosAcordeon,
                    bodega:[]
                })
                
                setShowInfoBodega(false)

            }else{
                searchInfoBodega(a.value)
                
            }
        }else{
            
            setDatosLotes({
                ...datosLotes,
                lote: e,
                tienda:a.tienda
            })
            
            // if(a.name === 'tienda'){
            //     localStorage.removeItem('datos')
            //     setDatosLotes({
            //     ...datosLotes,
            //     tienda: a.value,
            // })
            // si hay cambios y hay parametros, se eliminan
            // window.history.pushState(null, "", "/bodega/mercar/");
            // paramsMercar.lote= undefined
            // paramsMercar.tienda= undefined
            // setDatosAcordeon({
            //     ...datosAcordeon,
            //     ref:[],
            // })
            // setShow(false)
            // }
            // if(a.name === 'lote'){
            //     localStorage.removeItem('datos')
            // setDatosLotes({
            //     ...datosLotes,
            //     lote:a.value,
            //     tienda: '',
            // })
            // // si hay cambios y hay parametros, se eliminan
            // window.history.pushState(null, "", "/bodega/mercar/");
            // paramsMercar.lote= undefined
            // paramsMercar.tienda= undefined
            // listarTiendas('dist',a.value)
            // setDatosAcordeon({
            //     ...datosAcordeon,
            //     ref:[],
            // })
            // setShow(false)
            // }else{
            
                searchInfo(a.value)
            // }
            setBuscar({
                ...buscar,
                predist: ''
            })
        }
    }
    
    const onChangeRadio = async(e, tipo) => {
    
        if(tipo === 'predist'){
            //PREDISTRIBUCION
            setValue(
                { ...value,
                    predist: e?.target?.value
                });
            let b ={
                buscar:buscar.predist,
                page: currentPage.predist,
                filtro: e.target.value
            }
            localStorage.setItem('datos', (JSON.stringify(b)))
            
            setSp(true)
            const res = await Call('GET', `/api/mercar/cedi/lotes/${datosLotes.lote}/${nameCedi.codigo}/${e.target.value}${buscar.predist!== '' ? '?referencia='+(buscar && buscar.predist.toLowerCase().trim()):''}`, true, null, false)
            
            setDatosAcordeon({...datosAcordeon,
                ref: res.data.data
            })
            
            setSp(false)
        }else{
            //BODEGA
            
            setValue(
                { ...value,
                    bodega: e?.target?.value
                });
                
            let b ={
                buscar:buscar.bodega,
                page: currentPage.bodega,
                filtro: e?.target?.value
            }
            
                
            localStorage.setItem('datos2', (JSON.stringify(b)))
            setSp(true)
            setIsInitialLoad(false)
            setCurrentPageMercar(1);
            setPerPage(20);
            if (!buscar.bodega) {
                buscar.bodega = '' 
            }
            const res = await Call(
                'GET', 
                `/api/mercar/lotes/${datosLotesBodega.lote}/${e?.target?.value}?referencia=${buscar.bodega && buscar.bodega}&page=${1}&page_size=${perPage}`, 
                true, 
                null, 
                false
              );
            localStorage.setItem('page', 1);
            localStorage.setItem('page_size', 20);
            setDatosAcordeon({...datosAcordeon,
                bodega: res.data?.results?.data
            })
            
            setSp(false)

        }
    };
    
    const datosBonos = async (tienda)=>{
        setSp(true)
        const res = await Call('GET', `/api/bonos/lotes/destino/${datosLotesB.lote}/${tienda}`, true, null, false)
        setDetallesBonos(res.data.data)
        setShowBonos(true)
        setSp(false)

    }
    const listarTiendas = async(name, id) =>{
        
        setSp(true)
        
        if(name === 'bonos'){
            const res = await Call('GET', '/api/bonos/lotes/'+id, true, null, false)
            if(res.data.code === 2){
                setSp(false)
                Swal.fire({
                    title: '',
                    icon: 'info',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
                setListTiendasB([])
            }else{
                setListTiendasB(res.data.data)
                setSp(false)
            }
        
        }
        return
        {
           
           setSp(true)
            const res = await Call('GET', '/api/predistribucion/lotes/'+id, true, null, false)
           
            if(res.data.code === 2){
                Swal.fire({
                    title: '',
                    icon: 'info',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }

            if(paramsMercar.lote !== undefined){
                datosLotes.tienda=paramsMercar.tienda
                if(paramsMercar.tienda !== '' && paramsMercar.tienda !== undefined){
                    setDatosPagination(JSON.parse(localStorage.getItem('datos')))
                    let b= JSON.parse(localStorage.getItem('datos'))
                    
                    setBuscar(b.buscar)
                    searchInfo(nameCedi.codigo, b )
                    setValue({...value, predist:b.filtro})
                }
            
                setShow(false)
               
            }else{
                setSp(false)
                setShow(false)
            }
            setListTiendas(res.data.data)
            setSp(false)
        }
    }

    const handlechange =(e) =>{
       
        if(e.target.name === 'buscarpredist'){
           
            let b ={
                buscar:e.target.value,
                filtro: value.predist
            }
            setBuscar({
                ...buscar,
                predist: e.target.value
            })
            
            localStorage.setItem('datos', (JSON.stringify(b)))
            clearTimeout(time) 
            setTime(setTimeout(() => 
            {   
                if(e.target.value === ''){
                    searchInfo(datosLotes.lote)
                }else{
                    searchRef(e.target.value)
                }
            }, 800))  
        }else{
        
            // BODEGA
            let b ={
                buscar:e.target.value,
                page: currentPage.bodega,
                filtro: value.bodega
            }

            setBuscar({
                ...buscar,
                bodega: e.target.value
            })
          
            buscar.bodega=e.target.value
            
            localStorage.setItem('datos2', (JSON.stringify(b)))
            clearTimeout(time) 
            setTime(setTimeout(() => 
            {   
                // if(e.target.value === ''){
                //     searchInfo(datosLotesBodega .tienda)
                // }else{
                    searchInfoBodega (null, e.target.value)
                // }

            }, 800)) 
        }
        setCurrentPageMercar(1);
        localStorage.setItem('page', 1)
    }
    
    const searchRef = async(texto)=>{
       
        setSp(true)
        const res = await Call('GET', `/api/mercar/cedi/lotes/${datosLotes.lote}/${nameCedi.codigo}/${value.predist}${texto!== '' && '?referencia='+texto.toLowerCase().trim()}`, true, null, false)
        
        setDatosAcordeon({ 
            ...datosAcordeon,
             ref:res.data.data
        })
       
        setSp(false)
    }
    const searchInfo = async(tienda, datos)=>{
        
        if(tienda !== undefined){
            
            setSp(true)
            if(paramsMercar.tienda !== '' && paramsMercar.tienda !== undefined){
                
                let b = JSON.parse(localStorage.getItem('datos'))
                
                if(b){
                    if(b.buscar !== '' && b.buscar !== undefined && b.buscar !== null){
                    
                        setBuscar({
                            ...buscar,
                            predist: b.buscar&& b.buscar
                        })
                    }
                    
                    setValue({...value, 
                        predist: b.filtro
                    })
                    setSp(false)
                    setShow(true) 
                }
                               
            }else{
                let b = JSON.parse(localStorage.getItem('datos'))
            }
            
            try {
                setSp(true)
                const res = await Call('GET', `/api/mercar/cedi/lotes/${tienda}/${nameCedi.codigo}/${value.predist}${(datos  && datos.buscar !== '') ? '?referencia='+datos.buscar : ''}`, true, null, false)
                
                setDatosAcordeon({
                    ...datosAcordeon,
                    ref: res.data.data
                })
                            
                setSp(false)
                setShow(true)
            } catch (error) {
                setSp(false)
            }
        }else{
            setShow(false)
            setSp(false)
        }
    }
    
    const searchInfoBodega = async(tienda, datos)=>{
        setLote(datos)
       
        if(datosLotesBodega.lote !== undefined){
            
            setSp(true)
            
            if(paramsMercar.tienda !== '' && paramsMercar.tienda !== undefined){
                
                paramsMercar.tienda = 'b'
                let b = JSON.parse(localStorage.getItem('datos2'))
                
                if(b ){
                    if(b.buscar && b.buscar !== '')
                    setBuscar({
                        ...buscar,
                        bodega: b.buscar&& b.buscar.bodega
                    })
                    buscar.bodega = b.buscar?.bodega
                    
                   
                }
                nextPage2(b.page, 'mercar')
                setValue({
                    ...value,
                    bodega:b.filtro
                })
               value.bodega=b.filtro
                
                setShowInfoBodega(true)
                return;
                
            }else{
                setCurrentPage({
                    ...currentPage,
                     bodega: 1
                    })
            }

            let res=''
            if (buscar && buscar.bodega !== '') {
                res = await Call('GET', `api/mercar/lotes/${datosLotesBodega && datosLotesBodega.lote}/${value && value.bodega}?${(buscar.bodega !== undefined) ? 'referencia=' + (buscar.bodega) + '&' : ''}page=1&page_size=${perPage}`, true, null, false);
            } else {
                res = await Call('GET', `/api/mercar/lotes/${datos ? datos : datosLotesBodega.lote}/${value.bodega}?page=${currentPageMercar}&page_size=${perPage}`, true, null, false);
            }
            
            
            if(res.data.results.data){
                setDatosAcordeon({
                    ...datosAcordeon,
                    bodega: res.data?.results?.data
                })
                setTotalItems(res.data?.results?.count)
                
                setSp(false)
                setShowInfoBodega(true)
            }else{
                setSp(false)
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `${res.data.message}`,
                    confirmButtonText:'Aceptar',
                })
            }
        }else{
            setShowInfoBodega(false)
            setSp(false)
        }
    }
    const findMatchingOption = (loteValue, tiendas) => {
        if(datosLotes.tienda && datosLotes.tienda!=='' && datosLotes.tienda !== undefined){
            let b= listTiendas.filter((f)=>(f.codigo === datosLotes.tienda) )
            return b[0].lote +'-' +b[0].nombre
        }
       
      }

    const nextPage2 =async(page, where)=>{
        
        setSp(true)
       
        let b={}
        let buscarp={}
        if(where !== 'mercar'){
            b ={
                buscar:buscar.bodega,
                page: page,
                filtro:value.bodega
            }
            localStorage.setItem('datos2', (JSON.stringify(b)))
        }else{
            buscarp = JSON.parse(localStorage.getItem('datos2'))            
                if(buscarp.buscar !== ''){
                    setBuscar({
                        ...buscar,
                        bodega: buscarp.buscar
                    })
                }
                
                setValue({...value,
                    bodega:buscarp.filtro.bodega
                })

                value.bodega=buscarp.filtro
        }
        
        let url=''
        const savedPage = localStorage.getItem('page');
        const savedPageSize = localStorage.getItem('page_size');
        if(buscar && (buscar.bodega !== '')){
    
            url = `/api/mercar/lotes/${datosLotesBodega.lote}/${value.bodega}${(where === 'mercar' && (buscarp.buscar !== '' && buscarp.buscar !== undefined)) 
                ? '?referencia=' + buscarp.buscar 
                : ((buscar.bodega !== '' && buscar.bodega !== undefined) ? '?referencia=' + buscar.bodega.trim() : '')}`;
        
            if (url.includes('?')) {
                url += `&page=${savedPage || currentPageMercar}&page_size=${savedPageSize || perPage}`;
            }else {
                    url += `?page=${savedPage || currentPageMercar}&page_size=${savedPageSize || perPage}`;
                }
        }else{
            if (url.includes('?')) {
                url += `&page=${savedPage ?? currentPageMercar}&page_size=${savedPageSize ?? perPage}`;
            } else {
                url += `?page=${savedPage ?? currentPageMercar}&page_size=${savedPageSize ?? perPage}`;
            }
        }
        const res = await Call('GET',url, true, null, false)
        setDatosAcordeon({
            ...datosAcordeon,
            bodega: res.data?.results?.data
        })
        setTotalItems(res.data?.results?.count)
       
        
        setSp(false)
    }
    const handlechangeB = async(e)=>{
        
        setCodigo(e.target.value)
        let datos={
            accion:"agregar",
            plu:e.target.value
        }
        if(e.target.value !== ''){
            setSp(true)
            const res= await Call('POST', `/api/bonos/lotes/destino/mercar/${datosLotesB.lote}/${datosLotesB.tienda}`, true, datos, false)
            
            if(res.data.code === 1){
                setDetallesBonos({
                    ...detallesBonos,
                    mercadas: res.data.data.mercadas,
                    codigos:res.data.data.codigos
                })
                setCodigo('')
                setSp(false)
            }else{
                setCodigo('')
                setSp(false)
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `${res.data.message}`,
                    confirmButtonText:'Aceptar',
                })
            }
           
        }
    }
    const deletedPlu =async(plu)=>{
        let datos={
            accion:"eliminar",
            plu:plu
        }
        setSp(true)
        const res= await Call('POST', `/api/bonos/lotes/destino/mercar/${datosLotesB.lote}/${datosLotesB.tienda}`, true, datos, false)
            if(res.data.code === 1){
                setDetallesBonos({
                    ...detallesBonos,
                    mercadas: res.data.data.mercadas,
                    codigos: res.data.data.codigos
                    })
                setCodigo('')
                setSp(false)
            }else{
                setCodigo('')
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `${res.data.message}`,
                    confirmButtonText:'Aceptar',
                })
            }
    }
    const generarTraslado = async (tipo)=>{
        
        if(tipo === 'predist'){
            let data={}
            let arr=[]
            for(let i=0; i<datosAcordeon.ref.length; i++){
                if(datosAcordeon.ref[i].unidades === datosAcordeon.ref[i].total_mercadas){

                    data={
                        referencia:datosAcordeon.ref[i].referencia,
                        estado:true,
                        lote:datosLotes.lote,
                        origen:datosAcordeon.ref[i].origen,
                        cantidad:datosAcordeon.ref[i].unidades,
                        destino:datosLotes.tienda,
                        unidades: datosAcordeon.ref[i].unidades,
                        mercadas: datosAcordeon.ref[i].total_mercadas
                    }
                    arr.push(data)
                }
                if((datosAcordeon.ref[i].total_mercadas > 0) && (datosAcordeon.ref[i].total_mercadas  <datosAcordeon.ref[i].unidades)){
                    data={
                        referencia:datosAcordeon.ref[i].referencia,
                        estado:false,
                        lote:datosLotes.lote,
                        origen:datosAcordeon.ref[i].origen,
                        cantidad:datosAcordeon.ref[i].unidades,
                        destino:datosLotes.tienda,
                        unidades: datosAcordeon.ref[i].unidades,
                        mercadas: datosAcordeon.ref[i].total_mercadas
                    }
                    arr.push(data)
                }

                
            }                        
            //TRASFER PREDISTRIBUTION
            // Swal.fire({
            //     title:'',
            //     html: `¿Esta seguro de guardar el lote?`, 
            //     icon: 'info',
            //     showCancelButton: true,
            //     confirmButtonColor: '#23b991',
            //     cancelButtonColor: '#ee4630',
            //     confirmButtonText: 'Si',
            //     cancelButtonText:'No',
            //     customClass:{
            //     popup:'pop-up33',
            //     cancelButton:'btn-cancel-general',
            //     confirmButton:'btn-cancel-general',
            //     }
            //     }).then(async(result) => {
            //         if(result.isConfirmed){
            //             try {
            //                 setSp(true)
            //                 const res = await Call('GET', `/api/mercar/cedi/guardar/${datosLotes.lote}/${nameCedi.codigo}`, true, null, false)
            //                 if(res.data.code === 1){
            //                     setSp(false)
            //                     Swal.fire({
            //                         title: '',
            //                         icon: 'success',
            //                         html: res.data.message,
            //                         focusConfirm: false,
            //                         confirmButtonText:'Aceptar',
            //                     })
            //                     setDatosAcordeon([])
            //                     setDatosLotes({
            //                         lote:'',
            //                         tienda:''
            //                     })
            //                     window.history.pushState(null, "", "/bodega/mercar/") // LIMPIAR URL
            //                     localStorage.removeItem('datos')
            //                     listaLotes()
            //                     setShow(false)
            //                     setBuscar({
            //                         ...buscar,
            //                         predist:''
            //                     })

            //                 }else{
            //                     setSp(false)
            //                     Swal.fire({
            //                         title: '',
            //                         icon: 'error',
            //                         html: res.data.message,
            //                         focusConfirm: false,
            //                         confirmButtonText:'Aceptar',
            //                     })
            //                 }
            //             } catch (error) {
            //                 setSp(false)
            //             } 
            //         }
            //     })
            //TRANSFER PARTIAL
            Swal.fire({
                title:'',
                html: `¿El traslado es parcial?`, 
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#23b991',
                cancelButtonColor: '#ee4630',
                confirmButtonText: 'Si',
                cancelButtonText:'No',
                customClass:{
                popup:'pop-up33',
                cancelButton:'btn-cancel-general',
                confirmButton:'btn-cancel-general',
                }
              }).then(async(result) => {
                if(result.isConfirmed){
                    //tranfer partial
                    Swal.fire({
                        title:'',
                        html: `¿Desea confirmar el traslado parcial?`, 
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#23b991',
                        cancelButtonColor: '#ee4630',
                        confirmButtonText: 'Confirmar',
                        cancelButtonText:'Cancelar',
                        customClass:{
                        popup:'pop-up33',
                        cancelButton:'btn-cancel-general',
                        confirmButton:'btn-cancel-general',
                        }
                      }).then(async(result) => {
                       // TRASFER COMPLETE
                        if (result.isConfirmed) {
                            // setSp(true)
                            // const res = await Call('POST', `/api/bodegas/generar/${datosLotes.lote}/80`, true, {parcial: true}, false )
                            let dataTotal={
                                parcial: true,
                                referencias:arr
                            }
                            
                            const res = await Call('POST', `/api/mercar/cedi/guardar/${datosLotes.lote}/${nameCedi.codigo}`, true, dataTotal, false)
                            if(res.data.code === 1){
                                setSp(false)
                                Swal.fire({
                                    title: '',
                                    icon: 'success',
                                    html: res.data.message,
                                    focusConfirm: false,
                                    confirmButtonText:'Aceptar',
                                })
                                listaLotes()
                                
                            }else{
                                Swal.fire({
                                    title: '',
                                    icon: 'error',
                                    html: res.data.message,
                                    focusConfirm: false,
                                    confirmButtonText:'Aceptar',
                                }) 
                                setSp(false)
                            }
                        }
                    })
                    
                }else{
                    // COMFIRM TRANSFER
                    Swal.fire({
                        title:'',
                        html: `¿Desea confirmar el traslado?`, 
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#23b991',
                        cancelButtonColor: '#ee4630',
                        confirmButtonText: 'Confirmar',
                        cancelButtonText:'Cancelar',
                        customClass:{
                        popup:'pop-up33',
                        cancelButton:'btn-cancel-general',
                        confirmButton:'btn-cancel-general',
                        }
                      }).then(async(result) => {
                       
                        if (result.isConfirmed) {
                            setSp(true)
                            let dataTotal={
                                parcial: false,
                                referencias:arr
                            }
                            
                            const res = await Call('POST', `/api/mercar/cedi/guardar/${datosLotes.lote}/${nameCedi.codigo}`, true, dataTotal, false)
                            // const res = await Call('POST', `/api/bodegas/generar/${datosLotes.lote}/80`, true, null, false )
                            if(res.data.code === 1){
                                localStorage.removeItem('datos')
                                localStorage.removeItem('datos2')
                                Swal.fire({
                                    title: '',
                                    icon: 'success',
                                    html: res.data.message,
                                    focusConfirm: false,
                                    confirmButtonText:'Aceptar',
                                })
                                listaLotes('val')
                                setDatosAcordeon({
                                    ...datosAcordeon,
                                    ref:[]
                                })
                                setListTiendas([])
                                
                                setShow(false)
                                setBuscar({
                                    ...buscar,
                                    predist: '',
                                })
                               
                                setDatosLotes({
                                    ...datosLotes,
                                    tienda: ''
                                })
                                paramsMercar.tienda = ''
                            
                            }else{
                                Swal.fire({
                                    title: '',
                                    icon: 'error',
                                    html: res.data.message,
                                    focusConfirm: false,
                                    confirmButtonText:'Aceptar',
                                }) 
                                setSp(false)
                            }
                        }
                    })
                }
            })
        }else{
            // TRANSFER BONOS
            Swal.fire({
                title:'',
                html: `${tipo === 'bonos'? (detallesBonos.mercadas < detallesBonos.necesarias ? 'No se ha mercado la cantidad necesaria de bonos, ¿Desea continuar?': '¿Desea confirmar el traslado?') : '¿Desea confirmar el traslado?'}`, 
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#23b991',
                cancelButtonColor: '#ee4630',
                confirmButtonText: 'Confirmar',
                cancelButtonText:'Cancelar',
                customClass:{
                popup:'w-33 pop-up33',
                cancelButton:'btn-cancel-general',
                confirmButton:'btn-cancel-general',
                }
              }).then(async(result) => {
               
                if (result.isConfirmed) {
                    setSp(true)
                    
                        const res= await Call('POST', `/api/bonos/lotes/traslado/${datosLotesB.lote}/${datosLotesB.tienda}`, true, null, false)
                        
                        if(res.data.code === 1){
                            Swal.fire({
                                title: '',
                                icon: 'success',
                                html: res.data.message,
                                focusConfirm: false,
                                confirmButtonText:'Aceptar',
                            })
                            
                            setShowBonos(false)
                            setDetallesBonos([])
                            if(listTiendasB.length > 0){
                                if(listTiendasB.length ===1 ){
                                    setDatosLotesB({
                                        lote:'',
                                        tienda:''
                                    })
                                    setListTiendasB([])
                                    lotesBonos()
                                }else{
                                    setDatosLotesB({
                                        ...datosLotesB,
                                        tienda:''
                                    })
                                   
                                    listarTiendas('bonos', datosLotesB.lote)
                                }
                               
                                
                            }else{
                                setDatosLotesB({
                                    lote: '',
                                    tienda:''
                                })
                                lotesBonos()
                            }
                            setSp(false)
                        }else{
                            Swal.fire({
                                title: '',
                                icon: 'error',
                                html: res.data.message,
                                focusConfirm: false,
                                confirmButtonText:'Aceptar',
                            }) 
                            setSp(false)
                        }
                } else if (result.isDenied) {
                    setSp(false)
                }
              })
        }        
        // setSp(false)
    }
    const guardarLoteBodega= async()=>{
        
        setSp(true)
        const res = await Call('GET',  `api/mercar/guardar/${datosLotesBodega.lote}`, true, null, false)
        
        if(res.data.code === 1){
            Swal.fire({
                title: '',
                icon: 'success',
                html: res.data.message,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            }) 
            setDatosAcordeon({...datosAcordeon, bodega: []})
            setRegistroTotal(null)
            setSp(false)
            listLotesB()
            setDatosLotesBodega({
                lote:'',
                tienda:''
            })
        }else{
            Swal.fire({
                title: '',
                icon: 'error',
                html: res.data.message,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            }) 
            setSp(false)
        }
        
    }
    const verificar =()=>{
        lotesBodega()
        if(!loadGeneralBodega){
            setSp(true)
        }else{
            setSp(false)
        }
    }
    
    const lotesBodega= async(val)=>{
      
        window.history.pushState(null, "", "/bodega/mercar/") // LIMPIAR URL
       
        if(paramsMercar.tienda !== 'b'){
            paramsMercar.lote= undefined
            paramsMercar.tienda= undefined
        }
       
        setSp(true)
        const res = await Call('GET', `/api/mercar/lotes`, true, null, false)
        
        if(res.data.code === 1){
            setListLotesBodega(res.data.data.reverse())
            if(res.data.data.length > 0){
                setLoadGeneralBodega(true)
            }
                if(paramsMercar.lote !== undefined){
                    
                    let result = res.data.data.filter(word => word === paramsMercar.lote)
                    
                    if(result.length > 0 ){
                        datosLotesBodega.lote=paramsMercar.lote
                        searchInfoBodega('bodega',paramsMercar.lote)                        
                    }
                    // setShow(true)
                }else{
                    setSp(false)
                }
            }else{
                if(res.data.message === "No hay lotes disponibles"){
                    setSp(false)
                }else{
                    Swal.fire({
                        title: '',
                        icon: 'info',
                        html: res.data.message,
                        focusConfirm: false,
                        confirmButtonText:'Aceptar',
                    })
                    setSp(false)
                }  
            }
       
    }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (localStorage.getItem('returnMercar') === 'true') {
            localStorage.removeItem('returnMercar');
        } else {
            localStorage.removeItem('page');
            localStorage.removeItem('page_size');
        }
        if (isInitialLoad) {
            searchInfoBodega('bodega', lote)
        }
    }, [currentPageMercar, perPage, isInitialLoad]);
    const handlePerRowsChange = async (newPerPage, page) => {
        setIsInitialLoad(true)
        setPerPage(page);
        setCurrentPageMercar(newPerPage);
        localStorage.setItem('page', newPerPage);
        localStorage.setItem('page_size', page);
    };
    const handlePageChange = (page) => {
        setIsInitialLoad(true)
        setCurrentPageMercar(page);
        localStorage.setItem('page', page);
    };
    //COLUMNAS DATATBLE
    const columns=[
        {
            name: 'Ubicación',
            cell: row => (
                <div>
                    {row&& row.cod_ubicacion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Disponibles',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Agregadas',
            cell: row => (
                <div>
                    {row.mercadas}
                </div>
            ),
            filterable: true,
        },
        {
            name: '',
            cell: row => (
                <div>
                    <div className='btn-buscar cursor-pointer fs-16' onClick={()=>{irAMercar(row.cod_ubicacion, row.origen, row.referencia)}}>
                        Mercar
                    </div>                    
                </div>
            ),
            filterable: true,
        }
    ]
    // COLUMNS DATATABLE BODEGA
    const columnsBodega=[
        {
            name: 'Ubicación',
            cell: row => (
                <div>
                    {row.cod_ubicacion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Disponibles',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Agregadas',
            cell: row => (
                <div>
                    {row.mercadas}
                </div>
            ),
            filterable: true,
        },
        {
            name: '',
            cell: row => (
                <div>
                    <div className='btn-buscar cursor-pointer fs-16' onClick={()=>{irAMercar(row.cod_ubicacion, row.origen, row.referencia, 'bodega')}}>
                        Mercar
                    </div>
                </div>
            ),
            filterable: true,
        }
    ]
    // /////////// TIENDA VIRTUAL ////////
    const getListOrder=async()=>{
       //ORDER VIRTUAL STORE
        try {
            setSp(true)
            const res = await Call('GET', 'api/market/online_shop/list_orders', true, null, false)
           
            setListOrdersTV(res.data.data)
            setShowOrders(true)
            setSp(false)
        } catch (error) {
            setSp(false)
            
            Swal.fire({
                title: '',
                icon: 'error',
                html: error.data.message,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
        }
    }
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }; 

    const columnsOrders=[
        
        {
            name: 'Pedido',
            cell: row => (
                <div>
                    {row&& row.pedido}
                </div>
            ),
            
            filterable: true,
        },
        {
            name:'Und.',
            cell: row => (
                <div className='text-center'>
                    {row&& row.cantidad}
                </div>
            ),
            center:true,
            width:'50px'
        },
        {
            name: 'Bodega',
            cell: row => (
                <div>
                    {row&& row.bodega}
                </div>
            ),
            
            filterable: true,
        },

        
    ]
    const handleSelected = ({ selectedRows }) => {
        // ORDERS TO MERGE
        const pedidos = selectedRows.map((row) => row.pedido);
        setOrderSelected(pedidos)
    };
    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }
    const mergeOrders=async()=>{
        if(orderSelected.length === 0 ){
            Swal.fire({
                title: '',
                icon: 'info',
                html: 'Selecciona al menos un pedido.',
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
        }else{
            try {
                setSp(true)
                const res = await Call('POST', 'api/market/online_shop/group_orders', true, {group_order: orderSelected}, false)
                setOrderSelected([])
                setListOrdersTV(res.data.data)
                handleClearRows()
                Swal.fire({
                    title: '',
                    icon: 'success',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
                setDetsailLots([])
                setLotSelectTV('')
                setSp(false)
            } catch (error) {
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: error.response.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
            setSp(false)
        }
    }
    const getLotesVS = async(tipo)=>{
        
        try {
            setSp(true)
            const res = await Call('GET', 'api/market/online_shop/lot_list/', true, null, false)
         
            setVirtualStoreLote(res.data.data)
            setShowLotesVS(true)
            
            const verifyLot = res.data.data.filter(item=> item === loteSelectedTV )
            
            if(tipo === 'confirm'){
                if((verifyLot.length === 0) && (paramsMercar.lote === undefined)){
                    setLotSelectTV('')
                    setDetsailLots([])
                    setShowDetailsLots(false)
                }
            }
            
            setSp(false)
        } catch (error) {
            
        }
    }
    const onChangeVS=async(options, children)=>{  
        setLotSelectTV(options)      
        try {
            if(paramsMercar.lote){
                paramsMercar.lote= undefined
                paramsMercar.tienda= undefined
            }
            window.history.pushState(null, "", "/bodega/mercar/")
            setSp(true)
            const res = await Call('GET', `api/market/online_shop/lot_list/${options}`, true, null, false)
            setDetsailLots(res.data.data)
            setShowDetailsLots(true)
            setSp(false)
        } catch (error) {
            setSp(false)
            Swal.fire({
                title: '',
                icon: 'info',
                html: error,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
        }
        setSp(false)
    }
    const confirmRef=async(ubicacion, referencia, pedido)=>{

        const data={
            ubicacion,
            referencia,
            pedido,
            estado: true,
            lote:loteSelectedTV
        }
        Swal.fire({
            title: "",
            text: `¿Desea confirmar la referencia ${referencia} ?`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#23b991",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
            }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        setSp(true)
                        const res= await Call('POST', `/api/market/online_shop/status_location/${loteSelectedTV}`, true, {status:data}, false)
                        setDetsailLots(res.data.data.data)
                        getLotesVS('confirm')
                        setSp(false)
                        Swal.fire({
                            title: "",
                            text: `La referencia ${referencia} ha sido confirmada`,
                            icon: "success",
                            confirmButtonText:"Aceptar"
                        });
                    } catch (error) {
                        
                    }
                    setSp(false)
                }
            });
        setSp(false)
    }
    const customStyles = {
       
        headCells: {
            style: {
                paddingLeft: '2px', // override the cell padding for head cells
                paddingRight: '2px',
                fontSize: '14px',
            },
        },
        cells: {
            style: {
                paddingLeft: '2px', // override the cell padding for data cells
                paddingRight: '2px',
            },
        },
    };
    const customStylesM = {
       
        headCells: {
            style: {
                paddingLeft: '4px', // override the cell padding for head cells
                paddingRight: '4px',
                fontSize: '16px',
            },
        },
        cells: {
            style: {
                paddingLeft: '4px', // override the cell padding for data cells
                paddingRight: '4px',
                fontSize: '16px',
            },
        },
    };
    const conditionalRowStyles = [
        {
          when: row => row.estado,
          style: {
            backgroundColor: '#23b991',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      ];
    const columnsDetailLots =[
        {
            name: 'Pedido',
            cell: row => (
                <div>
                    {row&& row.pedido}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
            width:'128px'
        },
        {
            name: 'Ubicación',
            cell: row => (
                <div className='p-0'>
                    {row&& row.cod_ubicacion}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
            width:'85px'
        },
        {
            name: 'Almacén',
            cell: row => (
                <div className='text-capitalize text-center fw-600'>
                    {row&& row.nombre_almacen.toLowerCase()}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
            width:'95px',
        },
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row&& row.referencia}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
            width:'95px',
        },
        {
            name: 'Und. necesarias',
            cell: row => (
                <div className='p-0'>
                    {row&& row.cantidad}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Und. bodega',
            cell: row => (
                <div>
                    {row&& row.cantidad_en_bodega}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Mercar',
            cell: row => (
                <div>
                   {
                        (!row.estado && (row.cantidad_en_bodega !== '-') && (row.cantidad > 0)) ?
                            <div className='btn-buscar cursor-pointer fs-16' onClick={()=>{irAMercar(row.cod_ubicacion, row.cedi_id, row.referencia, 'virtualStore', row.cantidad)}}>
                                Mercar
                            </div>
                        :
                        '' 
                   }
                                       
                </div>
            ),
            center:true,
            filterable: true,
            width:'85px',
        },
        {
            name: '',
            cell: row => (
                !row.estado &&
                <div>
                    <i className="fas fa-check fs-18 cursor-pointer text-success" onClick={()=>{confirmRef(row.cod_ubicacion,row.referencia, row.pedido)}}></i>
                    
                </div>
            ),
            center:true,
            width:'40px',
            filterable: true, 
        }
    ]
    const columnsDetailLotsMov =[
        {
            name: 'Pedido',
            cell: row => (
                <div>
                    {row&& row.pedido}
                </div>
            ),
            center:true,
            filterable: true,
            sortable: true,
            width:'auto',
        },
        {
            name:'',
            cell:row=>(
                !row.estado &&
                    <div className='my-2'>
                        <i className="fas fa-check fs-20 cursor-pointer text-success" onClick={()=>{confirmRef(row.cod_ubicacion,row.referencia, row.pedido)}}></i> 
                    </div>                
            ),
            width:'60px',
        }
    ]
    const expandedComponentDetalle=(row)=>{
        return(
            detailsLots && detailsLots.map((a, indexa)=>(
                   ((a.pedido === row.data.pedido) && (a.cod_ubicacion === row.data.cod_ubicacion) && (row.data.referencia === a.referencia)) && 
                    <div className='py-2 d-block text-left' key={indexa}>
                    <div><span className='fw-600'>Referencia: </span> {a.referencia} </div>
                    <div><span className='fw-600'>Almacén: </span>{a.nombre_almacen&& a.nombre_almacen.toLowerCase()}</div>
                    <div><span className='fw-600'>Und. necesarias: </span> {a.cantidad} </div>
                    <div><span className='fw-600'>Und. bodega: </span>{a.cantidad_en_bodega }</div>
                    <div><span className='fw-600'>Ubicación: </span>{a.cod_ubicacion }</div>
                    <div>
                    {
                        ((!a.estado) && ((a.cantidad_en_bodega === '-') || (a.cantidad_en_bodega !== '-')) &&( a.cod_ubicacion !== 'Sin ubicación')) ?
                            <div className='btn-buscar cursor-pointer fs-16 mx-0 my-2 px-3' onClick={()=>{irAMercar(a.cod_ubicacion, a.cedi_id, a.referencia, 'virtualStore', a.cantidad)}}>
                                Mercar
                            </div>
                        :
                        '' 
                    }
                    </div>
                    
                </div>
            ))           
        )
    }
    const customSort = (rows, selector, direction) => {
        
        return rows.sort((rowA, rowB) => {
         // use the selector function to resolve your field names by passing the sort comparitors
        
         const aField = selector(rowA)
         const bField = selector(rowB)
       
         let comparison = 0;
       
         if (aField > bField) {
          comparison = 1;
         } else if (aField < bField) {
          comparison = -1;
         }
       
         return direction === 'desc' ? comparison * -1 : comparison;
        });
       };
    //  //// FIN TIENDA VIRTUAL
    const irAMercar = (ubicacion, origen, ref, tipo, undnecesarias)=>{

        const currentPage = localStorage.getItem('page') ? Number(localStorage.getItem('page')) : currentPageMercar;
        const pageSize = localStorage.getItem('page_size') ? Number(localStorage.getItem('page_size')) : perPage;

        localStorage.setItem('page', currentPage);
        localStorage.setItem('page_size', pageSize);
               
        if(tipo === 'bodega'){
            //GENERAL DATA LOCALSTORAGE
            let b ={
                buscar:buscar && buscar.bodega,
                page: currentPage.bodega,
                filtro: value.bodega
            }
            localStorage.setItem('datos2', (JSON.stringify(b)))
            
            props.history.push(`/bodega/ubicacion-inventario/${datosLotesBodega.lote}/${origen}/${ubicacion}/t/${ref}/b`)
        }else if(tipo === 'virtualStore'){
            //
            // let b ={
            //     page: currentPage.bodega,
            //     filtro: value.bodega
            // }
            // localStorage.setItem('datos', (JSON.stringify(b)))
            // localStorage.setItem('bodega', JSON.stringify([]) ) 
            props.history.push(`/bodega/ubicacion-inventario/${loteSelectedTV}/${origen}/${ubicacion}/tv/${ref}/tv/${undnecesarias}`)

        }else{
            //SEARCH IN PREDIST
            //GENERAL DATA LOCALSTORAGE
            let b ={
                buscar:buscar && buscar.predist,
                page: currentPage.predist,
                filtro: value.predist
            }
           
            localStorage.setItem('datos', (JSON.stringify(b)))
            props.history.push(`/bodega/ubicacion-inventario/${datosLotes.lote}/${origen}/${ubicacion}/${datosLotes.tienda}/${ref}`)
        }
        
    }

    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <TitleLayout data={datat} clase={"text-left"} icon={true} seccion={'mercar'} />
            <div className='bg-white p-2 col-lg-9 col-md-9 col-sm-12 mx-auto'>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {/* Si el usuario no es de AREA BODEGA, mostramos todas las pestañas */}
                    {!user.centro_costos.toUpperCase().includes("AREA BODEGA") ? (
                        <>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${(pestana === 'predistribucion') ? 'active' : ''}`} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => { setpestana('predistribucion') }}>Tiendas</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${(pestana === 'bonos') ? 'active' : ''}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => { lotesBonos(); setpestana('bonos') }}>Bonos</a>
                            </li>
                        </>
                    ) : null}

                    {/* Siempre mostramos Bodega y Tienda Virtual */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${(pestana === 'bodega') ? 'active' : ''}`} id="bodega-tab" data-toggle="tab" href="#bodega" role="tab" aria-controls="bodega" aria-selected="false" onClick={() => { verificar(); setpestana('bodega') }}>Bodega</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${(pestana === 'tiendaVirtual') ? 'active' : ''}`} id="tvirtual-tab" data-toggle="tab" href="#tvirtual" role="tab" aria-controls="tvirtual" aria-selected="false" onClick={() => { getListOrder(); setpestana('tiendaVirtual') }}>Tienda Virtual</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    {/* **************** MERCAR REFERENCIAS ******************* */}
                    <div className={`tab-pane fade ${pestana === 'predistribucion' ? 'show active': ''}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className='p-2 mb-4'>
                            {
                                (!loadGeneral && (listTiendas.length === 0)) ?
                                <div className="ticket-empty-modal">
                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                    <div className="text-empty">En este momento no hay lotes para mercar</div>
                                </div>
                                :
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 c-sec'>
                                        <span className='text-capitalize'>
                                            Origen: {nameCedi&& nameCedi.nombre.toLocaleLowerCase()}
                                        </span>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 c-sec'>
                                        { listTiendas&& listTiendas.length !== 0 &&
                                            <>
                                                <span className=''>
                                                    <Select
                                                        placeholder="Tienda"
                                                        optionFilterProp="children"
                                                        className='w-100'
                                                        onChange={(option,children)=>{onChange(option,children)}}
                                                        value={datosLotes.lote !=='' ? findMatchingOption(datosLotes.lote, datosLotes.codigo) : null }
                                                    >
                                                        {
                                                            listTiendas &&
                                                            listTiendas.map((ld, indexld)=>(
                                                                <Option key={indexld} name="tienda" tienda={ld.codigo} value={ld.lote}>{ld.lote +'-'+ ld.nombre}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </span>
                                            </>
                                        }
                                    </div>
                            </div>

                            }
                            {/* ------------------------- ACORDEON ------------------------- */}
                            <div className='pt-3 mb-2'>
                                { show &&
                                    <>
                                        <div className='mb-4'>
                                            <input type="text" 
                                                className='form-control form-control-sm input-buscar-listform' 
                                                name="buscarpredist" placeholder="&#xF002;  Buscar" 
                                                onChange={handlechange} 
                                                value={buscar && buscar.predist} />
                                        </div>
                                        <div>
                                            <div className='d-flex'>
                                                <Radio.Group onChange={(value)=>onChangeRadio(value, 'predist')} value={value && value.predist}>
                                                    <Radio value={'todas'}>Todas</Radio>
                                                    <Radio value={'pendientes'}>Pendientes</Radio>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </>
                                }
                                
                                <div className="accordion mt-2" id="accordionExample">
                                    { datosAcordeon.ref && datosAcordeon.ref.length > 0 ?
                                        datosAcordeon.ref.map((a, indexa)=>(
                                            <div  key={indexa} >
                                                <div className={`accordion-header ${a.unidades === a.total_mercadas ? 'mercadas' : ''}`} id="headingOne"  type="button" data-toggle="collapse" data-target={`#l-${a.referencia}`} aria-expanded="false" aria-controls="collapseOne">
                                                    <h2 className="mb-0">
                                                        <span className="btn btn-block text-left text-capitalize fw-600 d-flex justify-content-between flex-wrap" >
                                                            <div className='d-flex flex-wrap'><span>{a.referencia}</span></div>
                                                            <div><span>Necesarias:</span><span>{a.unidades}</span></div>
                                                            <div><span>Agregadas:</span> {a.total_mercadas}</div>
                                                        </span>
                                                    </h2>
                                                </div>

                                                <div id={`l-${a.referencia}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                    <div className="card-body mercarTable p-0">
                                                        {
                                                            a.ubicaciones.length>0 ?
                                                                <DataTableComp 
                                                                    columns={columns} 
                                                                    data={a.ubicaciones} 
                                                                    className="mercarTable" 
                                                                    customStyles={customStyles}
                                                                />
                                                            :
                                                            <div className="ticket-empty-modal pt-0">
                                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                                <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                            show &&
                                                <div className="ticket-empty-modal pt-2">
                                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                </div>
                                    }
                                    </div>
                                </div>
                                {
                                    show &&
                                    <div className='fixed-btn'>
                                        <div onClick={()=>generarTraslado('predist')} className='btn-buscar fw-400 cursor-pointer'>
                                        Generar traslado
                                        </div> 
                                    </div>
                                }
                        </div>
                    </div>
                    {/* **************** FIN MERCAR REFERENCIAS ******************* */}
                    {/* **************** MERCAR BONOS ******************* */}
                    <div className={`tab-pane fade ${pestana === 'bonos'? 'show active': ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className='p-2'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    Lote
                                    <Select
                                        showSearch
                                        placeholder="Lote"
                                        optionFilterProp="children"
                                        className='w-100'
                                        value={datosLotesB.lote}
                                        onChange={(option,children)=>{onChange(option,children)}}
                                        name="lote"
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            listLotesB &&
                                            listLotesB.map((lt, indexlt)=>(
                                                <Option key={indexlt} id="bonos" name="lote" value={lt.id}>{lt.id +' - '+ moment(lt.fecha).format('DD/MM/YYYY')}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    {
                                        listTiendasB.length !== 0 &&
                                        <>
                                        Tienda
                                        <Select
                                            showSearch
                                            placeholder="Tienda"
                                            optionFilterProp="children"
                                            className='w-100'
                                            onChange={(option,children)=>{onChange(option,children)}}
                                            // onSearch={onSearch}
                                            value={datosLotesB.tienda}
                                            filterOption={(input, option) =>
                                            (option.children).toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                listTiendasB &&
                                                listTiendasB.map((ld, indexld)=>(
                                                    <Option key={indexld} name="tienda" id='bonos' value={ld.codigo}>{ld.nombre}</Option>
                                                ))
                                            }
                                        </Select>
                                        </>
                                    }
                                </div> 
                            </div>
                            { showBonos &&
                                <>
                            
                                    <div className='d-flex flex-wrap justify-content-between my-4'>
                                        <div>
                                            <span className='fw-600'>Necesarias:</span><span> {detallesBonos&& detallesBonos.necesarias}</span>
                                        </div>
                                        <div>
                                            <span className='fw-600'>Mercadas: </span><span> {detallesBonos&& detallesBonos.mercadas}</span>
                                        </div>
                                        
                                    </div>      
                                    <div className='mb-4'>
                                        {/* Código */}
                                        <input type="text" 
                                            className='form-control form-control-sm input-buscar-listform' 
                                            name="codigo" placeholder="Código" 
                                            onChange={(e)=>{setCodigo(e.target.value)}} 
                                            value={codigo} 
                                            onKeyUp={(e)=>(e.keyCode === 13 ?handlechangeB(e): '')}
                                        />
                                    </div>
                                    <div>
                                    {   detallesBonos&&
                                            detallesBonos.codigos.length> 0 ?
                                                <>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">PLU</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                detallesBonos&&
                                                                detallesBonos.codigos.map((b, indexb)=>(
                                                                    <tr key={indexb}>
                                                                        <td>{b.plu}</td>
                                                                        <td><i className="fas fa-times fs-16 text-danger cursor-pointer" onClick={()=>deletedPlu(b.plu)}></i></td>
                                                                    </tr>   
                                                                ))

                                                            }
                                                                                                
                                                        </tbody>
                                                    </table>
                                                    <div className='mt-5'>
                                                        <div onClick={()=>generarTraslado('bonos')} className='btn-buscar fw-400 cursor-pointer'>
                                                            Generar traslado
                                                        </div> 
                                                    </div>
                                                </>
                                            :
                                            <div className="ticket-empty-modal">
                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                <div className="text-empty">En este momento no hay bonos mercados</div>
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {/* **************** FIN MERCAR BONOS ******************* */}
                    {/* ***************** MERCAR BODEGA ********************* */}
                    <div className={`tab-pane fade ${pestana === 'bodega'? 'show active': ''}`} id="bodega" role="tabpanel" aria-labelledby="bodega-tab">
                        <div className='p-2'>
                        {
                            (!loadGeneralBodega && (listLotesBodega.length === 0)) ?
                                <div className="ticket-empty-modal">
                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                    <div className="text-empty">En este momento no hay lotes para mercar</div>
                                </div>
                                :
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        Lote
                                        <Select
                                            // showSearch
                                            placeholder="Lote"
                                            optionFilterProp="children"
                                            className='w-100'
                                            value={datosLotesBodega.lote}
                                            onChange={(option,children)=>{onChange(option,children)}}
                                            name="lote"
                                            // onSearch={onSearch}
                                            // filterOption={(input, option) =>
                                            //     (option.children).toLowerCase().includes(input.toLowerCase())
                                            // }
                                        >
                                            {
                                                listLotesBodega &&
                                                listLotesBodega.map((lt, indexlt)=>(
                                                    <Option key={indexlt} id="bodega" name="lote" value={lt}>{lt}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 c-sec'>
                                
                                    </div>
                                </div>
                        }
                        {/* ------------------------- ACORDEON ------------------------- */}
                        <div className='pt-2 mb-5'>
                                { showInfoBodega &&
                                    <>
                                        <div className='mb-4'>
                                            <input type="text" className='form-control form-control-sm input-buscar-listform' name="buscarBodega" placeholder="&#xF002;  Buscar" onChange={handlechange} value={buscar.bodega} />
                                        </div>
                                        <div>
                                            <div className='d-flex'>
                                                <Radio.Group onChange={(value)=>{onChangeRadio(value, 'bodega')}} value={value.bodega}>
                                                    <Radio value={'todas'}>Todas</Radio>
                                                    <Radio value={'pendientes'}>Pendientes</Radio>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </>
                                }
                                
                                <div className="accordion mt-2" id="accordionExample">
                                    { datosAcordeon.bodega && datosAcordeon.bodega.length > 0 ?
                                        datosAcordeon.bodega.map((a, indexa)=>(
                                            <div  key={indexa} >
                                                <div className={`accordion-header ${a.unidades === a.total_mercadas ? 'mercadas' : ''}`} id="headingOne"  type="button" data-toggle="collapse" data-target={`#l-${a.referencia}`} aria-expanded="false" aria-controls="collapseOne">
                                                    <h2 className="mb-0">
                                                        <span className="btn btn-block text-left text-capitalize fw-600 d-flex justify-content-between flex-wrap" >
                                                            <div className='d-flex flex-wrap'><span>{a.referencia}</span></div>
                                                            <div><span>Necesarias:</span><span>{a.unidades}</span></div>
                                                            <div><span>Agregadas:</span> {a.total_mercadas}</div>
                                                        </span>
                                                    </h2>
                                                </div>

                                                <div id={`l-${a.referencia}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                    <div className="card-body mercarTable p-0">
                                                        {
                                                            a.ubicaciones.length>0 ?
                                                            <DataTableComp 
                                                                columns={columnsBodega}
                                                                data={a.ubicaciones}
                                                                className="mercarTable"
                                                            />
                                                            :
                                                            <div className="ticket-empty-modal pt-0">
                                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                                <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                            </div>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                            showInfoBodega &&
                                                <div className="ticket-empty-modal pt-2">
                                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                </div>
                                    }
                                    </div>
                                    
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        {datosAcordeon.bodega && datosAcordeon.bodega.length > 0 && (
                                            <Pagination
                                                current={localStorage.getItem('page') ? Number(localStorage.getItem('page')) : currentPageMercar}
                                                total={totalItems}
                                                pageSize={localStorage.getItem('page_size') ? Number(localStorage.getItem('page_size')) : perPage}
                                                onChange={handlePageChange}
                                                onShowSizeChange={handlePerRowsChange}
                                                showSizeChanger
                                                pageSizeOptions={['10', '20', '50', '100']}
                                            />
                                        )}
                                    </div>
                                </div>
                                <br></br>

                                {
                                    showInfoBodega && 
                                    <>
                                        <div className='fixed-btn  py-0'>
                                            <div onClick={()=>guardarLoteBodega()} className='btn-buscar fw-400 cursor-pointer px-4 my-3'>
                                                Cerrar lote
                                            </div> 
                                        </div>
                                    </>
                                }
                        </div>
                    </div>
                    {/* **************** FIN MERCAR BODEGA ******************* */}
                    {/* **************** INICIO MERCAR TIENDA VIRTUAL ********* */}
                    <div className={`tab-pane fade ${pestana === 'tiendaVirtual' ? 'show active': ''}`} id="tvirtual" role="tabpanel" aria-labelledby="tvirtual-tab">
                        
                            {   !seeOrderList ?
                                <>
                                    <div className='p-4 d-flex justify-content-between flex-wrap'>
                                        <div></div>
                                        <div className='btn-general-green' onClick={()=>{setSeeOrderList(true); getLotesVS()}}>Ver lotes</div>
                                    </div>
                                    { listOrdersTV.length >0 ? 
                                    
                                        showOrders &&
                                        <div>
                                            <section className='mercarTable widthDataTable'>
                                            <DataTableComp
                                                columns={columnsOrders}
                                                data={listOrdersTV}
                                                selectableRows={true}
                                                onSelectedRowsChange={handleSelected}
                                                clearSelectedRows={toggledClearRows}
                                                paginationPerPage={15}
                                                paginationComponentOptions={paginationComponentOptions}
                                                customStyles={customStyles}
                                                />
                                            </section>
                                            <section className='mt-3'>
                                                <div className='btn-general-green' onClick={mergeOrders}>
                                                    Agrupar pedidos
                                                </div>
                                            </section>
                                        </div>
                                        :
                                        <>
                                            <div className='mx-auto text-center'>
                                                <i className="fas fa-box-open fs-30 mb-2"></i>
                                                <div>En este momento no hay Pedidos disponibles</div>
                                            </div>
                                           
                                        </>

                                    }
                                </>
                                :
                                <>
                                    <div className='p-4 d-flex justify-content-between flex-wrap'>
                                        <div></div>
                                        <div className='btn-general-green' onClick={()=>{setSeeOrderList(false); setShowLotesVS(false); getListOrder()}}>Ver Pedidos</div>
                                    </div>
                                    
                                        { showlotesVS &&
                                            <>
                                            {
                                                virtualStoreLote && virtualStoreLote.length === 0 ?
                                                <>
                                                    <div className='mx-auto text-center'>
                                                        <i className="fas fa-box-open fs-30 mb-2"></i>
                                                        <div>En este momento no hay lotes disponibles.</div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                                        Lote
                                                        <Select
                                                            showSearch
                                                            placeholder="Lote"
                                                            optionFilterProp="children"
                                                            className='w-100'
                                                            value={loteSelectedTV}
                                                            onChange={(option,children)=>{onChangeVS(option,children)}}
                                                            name="loteTV"
                                                            // onSearch={onSearch}
                                                            
                                                            filterOption={(input, option) =>
                                                                (option.children).toLowerCase().includes(input.toLowerCase())
                                                            }
                                                        >
                                                            {
                                                                virtualStoreLote &&
                                                                virtualStoreLote.map((lt, indexlt)=>(
                                                                    <Option key={indexlt} id="loteTV" name="loteTV" value={lt}>{lt}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </div>
                                                    
                                                        {
                                                            showDetailsLots&& 
                                                            <>
                                                                <div className='ocultar-movil'>
                                                                    <DataTableComp
                                                                        columns={columnsDetailLots}
                                                                        data={detailsLots && detailsLots}
                                                                        customStyles={customStyles}
                                                                        sortFunction={customSort}
                                                                        paginationPerPage={15}
                                                                        paginationComponentOptions={paginationComponentOptions}
                                                                        conditionalRowStyles={conditionalRowStyles}
                                                                    />
                                                                </div>
                                                                <div className='mostrar-movil'>
                                                                    <DataTableCompMovil 
                                                                         columns={columnsDetailLotsMov}
                                                                         data={detailsLots && detailsLots}
                                                                         customStyles={customStylesM}
                                                                         sortFunction={customSort}
                                                                         paginationPerPage={15}
                                                                         expandedComponent={expandedComponentDetalle}
                                                                         paginationComponentOptions={paginationComponentOptions}
                                                                         conditionalRowStyles={conditionalRowStyles}
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                       
                                                    
                                                </>
                                                }
                                            </>
                                        }
                                        
                                </>
                            }
                    </div>
                    {/* **************** FIN MERCAR TIENDA VIRTUAL *********** */}
                </div>
                </div>
                
        </div>
    )
}
export default Index;