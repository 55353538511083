import React, { useState, useCallback, useEffect } from 'react';

const CardIndicadores = ({ indicadores = {} }) => {
    const [currentSetIndex, setCurrentSetIndex] = useState(0); 

    const currentData = indicadores.results ? indicadores.results[0] : null;

    const cards = [
        {
            title: "Unidad promedio transacción (UPF)",
            tienda: currentData?.promedios?.unidad_promedio_trasaccion_tienda,
            vendedor: currentData?.promedios?.unidad_promedio_trasaccion_vendedor,
            tiendaLabel: "Tienda",
            vendedorLabel: "Vendedor",
            comparisonMessage: currentData?.promedios?.unidad_promedio_trasaccion_tienda > currentData?.promedios?.unidad_promedio_trasaccion_vendedor
                ? "😊 ¡Estás por encima del promedio de unidades de transacción!"
                : "😞 Estás por debajo del promedio de unidades de transacción",
            valueFormatter: (value) => value.toFixed(2),
        },
        {
            title: "Promedio de valor por transacción (VPF)",
            tienda: currentData?.promedios?.venta_por_transaccion_tienda,
            vendedor: currentData?.promedios?.venta_por_transaccion_vendedor,
            tiendaLabel: "Tienda",
            vendedorLabel: "Vendedor",
            comparisonMessage: currentData?.promedios?.venta_por_transaccion_vendedor > currentData?.promedios?.venta_por_transaccion_tienda
                ? "😊 ¡Estás por encima del precio promedio por transacción!"
                : "😞 Estás por debajo del precio promedio por transacción",
            valueFormatter: (value) => new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value),
        },
        {
            // Card con más información detallada
            title: "Precio promedio por unidad (PPU) con más detalles",
            content: (
                <>
                    <h5>
                        <i className="fas fa-chart-line fs-19"></i> Precio promedio por unidad (PPU)
                    </h5>
                    <div className="d-flex justify-content-between">
                        <div className="flex-column p-2">
                            <h6>
                                <i className="fas fa-solid fa-store"></i> Tienda
                            </h6>
                            <p>
                                <strong>Venta total:</strong>{" "}
                                {currentData?.venta_total_tienda !== null && currentData?.venta_total_tienda !== undefined
                                    ? new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(currentData.venta_total_tienda)
                                    : "Sin datos"}
                            </p>
                            <p>
                                <strong>Cantidad total:</strong>{" "}
                                {currentData?.cantidad_total_tienda !== null && currentData?.cantidad_total_tienda !== undefined
                                    ? currentData.cantidad_total_tienda
                                    : "Sin datos"}
                            </p>
                            <p>
                                <strong>Precio promedio:</strong>{" "}
                                {currentData?.precio_promedio_tienda !== null && currentData?.precio_promedio_tienda !== undefined
                                    ? new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(currentData.precio_promedio_tienda)
                                    : "Sin datos"}
                            </p>
                        </div>

                        <div className="flex-column p-2">
                            <h6>
                                <i className="fas fa-solid fa-user-tie"></i> Vendedor
                            </h6>
                            <p>
                                <strong>Venta total:</strong>{" "}
                                {currentData?.venta_total_vendedor !== null && currentData?.venta_total_vendedor !== undefined
                                    ? new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(currentData.venta_total_vendedor)
                                    : "Sin datos"}
                            </p>
                            <p>
                                <strong>Cantidad total:</strong>{" "}
                                {currentData?.cantidad_total_vendedor !== null && currentData?.cantidad_total_vendedor !== undefined
                                    ? currentData.cantidad_total_vendedor
                                    : "Sin datos"}
                            </p>
                            <p>
                                <strong>Precio promedio:</strong>{" "}
                                {currentData?.precio_promedio_vendedor !== null && currentData?.precio_promedio_vendedor !== undefined
                                    ? new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(currentData.precio_promedio_vendedor)
                                    : "Sin datos"}
                            </p>
                        </div>
                    </div>

                    {currentData?.precio_promedio_vendedor > currentData?.precio_promedio_tienda ? (
                        <p className="m-regular">😊 ¡Estás por encima del precio promedio por unidad!</p>
                    ) : (
                        <p className="m-regular">😞 Estás por debajo del precio promedio por unidad</p>
                    )}
                </>
            )
        }
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNext();
        }, 20000);

        return () => clearInterval(intervalId);
    }, [currentSetIndex]);

    const handleNext = useCallback(() => {
        setCurrentSetIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, [cards.length]);

    const handlePrev = useCallback(() => {
        setCurrentSetIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    }, [cards.length]);

    const currentCard = cards[currentSetIndex];

    return (
        <div className="card-general d-flex flex-column justify-content-between">
            <div className="carousel-content text-center flex-grow-1">
                {currentData && currentCard ? (
                    <>
                        {currentCard.content ? (
                            currentCard.content
                        ) : (
                            <>
                                <h5>
                                    <i className="fas fa-chart-line fs-19"></i> {currentCard.title}
                                </h5>
                                <h6>
                                    <i className="fas fa-solid fa-store"></i> {currentCard.tiendaLabel}
                                </h6>
                                <p>
                                    {currentCard.tienda !== null && currentCard.tienda !== undefined
                                        ? currentCard.valueFormatter(currentCard.tienda)
                                        : "Sin datos"}
                                </p>
                                <h6>
                                    <i className="fas fa-solid fa-user-tie"></i> {currentCard.vendedorLabel}
                                </h6>
                                <p>
                                    {currentCard.vendedor !== null && currentCard.vendedor !== undefined
                                        ? currentCard.valueFormatter(currentCard.vendedor)
                                        : "Sin datos"}
                                </p>

                                <p>{currentCard.comparisonMessage}</p>
                            </>
                        )}
                                    <div className="d-flex justify-content-between mt-3" style={{ gap: '20px' }}>
                <button className="btn-info2 fit-content" onClick={handlePrev}>Anterior</button>
                <button className="btn-info2 fit-content" onClick={handleNext}>Siguiente</button>
            </div>
                    </>
                ) : (
                <div className='bg-white p-4 col-lg-12 col-md-12 col-sm-12 mx-auto' style={{height:'auto'}}>
                    <h5 className='text-center'><i className="fas fa-chart-line fs-19"></i>  Indicadores comerciales </h5>
                    <div className='my-5 text-center'>
                        <i className="fas fa-box-open fs-40"></i>
                        <div>En estos momentos no hay datos disponibles</div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default CardIndicadores;
