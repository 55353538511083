import axios from 'axios';
import { decode } from '../config/crypto.js';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

// Crear una instancia de Axios
const request = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:8000/',
  withCredentials: true,
});

// Interceptor para añadir CSRF Token, session ID y token de autenticación
request.interceptors.request.use(
  config => {
    try {
      // Obtener CSRF Token y session ID de las cookies
      const csrfToken = Cookies.get('csrftoken');
      const sessionID = Cookies.get('sessionid');

      if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
      }

      if (sessionID) {
        config.headers['sessionid'] = sessionID;
      }

      // Obtener el token de autenticación desde localStorage
      const authData = localStorage.getItem('authInfo') ? decode(localStorage.getItem('authInfo')) : null;
      const token = authData ? authData.token : null;

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

    } catch (error) {
      return Promise.reject(error);
    }

    return config;
  },
  error => Promise.reject(error)
);

// Manejar errores de Axios
request.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response) {
      const { status } = response;

      if (status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data[0],
        });
      } else if (status === 401) {
        localStorage.clear();
        window.location.replace('/');
      } else if (status === 402 || status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ops, ha ocurrido un error reiniciar el navegador o contactar con soporte',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se pudo conectar con el servidor',
      });
    }

    return Promise.reject(error);
  }
);

const Header = (method, url, isAuth = false, data = null, toSendAFile = false, timeout = null) => {  
  let options = {
    method,
    url,
    data,
    headers: {
      'Content-Type': toSendAFile ? 'multipart/form-data' : 'application/json',
    },
    timeout,
  };
  if (toSendAFile) {
    delete options.headers['Content-Type'];
  }

  if (isAuth) {
    const authData = localStorage.getItem('authInfo') ? decode(localStorage.getItem('authInfo')) : null;
    const token = authData ? authData.token : null;

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  return new Promise((resolve, reject) => {
    request(options)
      .then(res => resolve(res))
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message === 'Given token not valid for any token type') {
          localStorage.clear();
          window.location.replace('/');
        }

        Swal.fire({
          title: '',
          icon: 'error',
          html: error.response ? error.response.data.message : 'Error interno',
          focusConfirm: false,
          confirmButtonText: 'Aceptar',
        });

        reject(error);
      });
  });
};

export default Header;
