import React, {useState, useEffect} from 'react'
import Call from '../../../../config/Call'
import Spinner from '../../../base/spiner';
import { Select } from 'antd';
import Swal from 'sweetalert2';

const { Option } = Select;

const Transfer = (props) => {

    const [sp, setSp]= useState(false)
    const [ listBodegas, setListBodegas ]= useState([])
    const [ data, setData ]= useState({bodega:'', type:'estanterias'})
    const [ listTYpe, setListType ] = useState([])
    const [listOrigin, setListOrigin]=useState([])
    const [buscar, setBuscar]= useState({ppal:'', modal:''})
    const [ showModal, setShowModal] = useState(false)
    const [listModal, setListModal]= useState([])
    const [listModalDestination, setModalDestination]=useState([])
    const [origin, setOrigin]= useState('')
    const [destination, setDestination] = useState('')
    const [time, setTime] =useState('') //MODIFICAR TIME

    useEffect(()=>{
        setListBodegas(props.listBodegas)
        
    },[])
    
    const handleChangeSelect = (options, children)=>{
        
        if(children.name === 'bodega'){
            setData({
                ...data,
                bodega:options
            })
            listData(options)
        }
        if(children.name === 'tipo'){
            setData({
                ...data,
                type:options
            })
            data.type=options
            listData()
        }
        setBuscar({ppal:'', modal:''})
    }
    const listData = async(bodega)=>{
       
            try {
                setSp(true)
                const res = await Call('GET', `/api/bodegas/listado_eliminar/${data.type}/${bodega? bodega : data.bodega}/transferir`, true, null, false);
                let list=[]
                
                for (let i in res.data.data ){
                    if(data.type === 'estanterias'){
                        let listEstanterias={
                            col: res.data.data[i],
                            status:false
                        }
                        list.push(listEstanterias)
                    }
                    
                    if(data.type ==='canastas'){
                        let arrayRef=[]
                        for(let j in res?.data?.data[i]?.referencias[j]?.referencia){
                          
                                let ref= {
                                    referencia: res.data.data[i].referencias[j].referencia,
                                    cantidad: res.data.data[i].referencias[j].cantidad,
                                    status:false
                                }
                                arrayRef.push(ref)
                           } 
                           let d={
                            can: res.data.data[i].cod_ubicacion,
                            status:false,
                                referencias:arrayRef
                            }
                            list.push(d)
                        }
                        
                    
                }
                
                setListType(list)
                setListOrigin(list)
                setSp(false)
            } catch (error) {
                alert(error)
            }
    }
    const handleChange=(e)=>{
        for(let i in listOrigin){
            if(listOrigin[i].col === e.target.value){
                listOrigin[i].status= true
            }else{
                listOrigin[i].status=false
            }
        }
        listDestination(e.target.value)
    }
    const listDestination=async(dato)=>{
        try {
            setSp(true)
            const res = await Call('POST', `api/bodegas/listado_destino/estanterias/${data.bodega}`, true, {transfer_type:dato}, false)
            let array=[]
            let estanterias=res.data?.data
            for(let estanteria in estanterias){
                let data={
                    col: estanterias[estanteria],
                    status:false
                }
                array.push(data)
            }
            setModalDestination(array)
            setListModal(array)
            setShowModal(true)
            setOrigin(dato)
            setSp(false)
        } catch (error) {
            setSp(false)
        }
       
    }
    const handlechangeSearch=(e, type)=>{
        if(type === 'ppal'){
            setBuscar({...buscar,
                ppal:e.target.value
            })
            if(e.target.value !== ''){
                clearTimeout(time) 
                setTime(setTimeout(() => 
                {   
                    let res= listOrigin.filter(elemento => elemento.col.includes( `${e.target.value}`))
                    setListType(res)        
                }, 800))  
            }else{
                setListType(listOrigin)
            }
        }else{
            setBuscar({...buscar,
                modal:e.target.value
            })
            if(e.target.value !== ''){
                clearTimeout(time) 
                setTime(setTimeout(() => 
                {   
                    let res= listModalDestination.filter(elemento => elemento.col.includes( `${e.target.value}`))
                    setListModal(res)        
                }, 800))  
            }else{
                setListModal(listModalDestination)
            }
        }   
       
    }
    const handleChangeModal=(e)=>{
        setDestination(e.target.value)
        for(let destination in listModalDestination){
            if(listModalDestination[destination].col === e.target.value){
                listModalDestination[destination].status=true
            }else{
                listModalDestination[destination].status=false
            }
        }
    }
    const closeModal=()=>{
        setShowModal(false)
        setListModal([])
        setDestination('')
        setOrigin('')
        setModalDestination([])
        for(let j in listOrigin){
            
            listOrigin[j].status=false
        }

    }
    const transfer=async()=>{
        let transfer= {
            "origin": origin,
            "destination": destination
        }
        Swal.fire({
            title: "",
            text: "¿Desea realizar la transferencia?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#23b991",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
            }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        setSp(true)
                        const res = await Call('POST', `api/bodegas/transferir/estanterias/${data.bodega}`, true,{transfer}, false)
                        let list=[]
                        for (let i in res.data.data ){
                            if(data.type === 'estanterias'){
                                let d={
                                    col: res.data.data[i],
                                    status:false
                                }
                                list.push(d)
                            }
                        }
                        setListOrigin(list)
                        setListType(list)
                        setSp(false)
                        Swal.fire({
                            title: '',
                            icon: 'success',
                            html: res.data.message,
                            focusConfirm: false,
                            confirmButtonText:'Aceptar',
                        })
                        setShowModal(false)
                        setBuscar({ppal:'', modal:''})
                        setOrigin('')
                        setDestination('')
                        setModalDestination([])
                    } catch (error) {
                        setSp(false)
                    }
                }
            })        
    }
  return (
    <div className='px-4'>
        { sp ? <Spinner /> : ''}  {/*LOADER*/}
        {showModal &&
            <>
                <div className="modal" style={{display:'block', opacity:'1'}} data-backdrop-static="true" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"  role="document">
                    <div className="modal-content" style={{height:'80vh'}}>
                        <div className="modal-header py-1">
                            <span className='fw-600'>{data.type === 'estanterias'? 'Columnas':'Canastas'}</span>
                            <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{height:'80vh', overflow:'auto'}}>
                            <h6>Transferir {origin} a:</h6>
                            {   listModal&& listModal.length>0 ?
                                <>
                                    <div className='my-3'>
                                        <input 
                                            type="text" 
                                            className='form-control form-control-sm input-buscar-listform' 
                                            name="buscarpredist" 
                                            placeholder="&#xF002;  Buscar" 
                                            onChange={(e)=>handlechangeSearch(e, 'modal')} 
                                            value={buscar && buscar.modal} 
                                        />
                                    </div>
                                    {
                                        listModal&& listModal.map((estanteria, indexm)=>(
                                            <div className="form-check" key={indexm}>
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="exampleRadios" 
                                                id={`${estanteria.col}`}  
                                                value={estanteria.col} 
                                                checked={estanteria.status}
                                                onChange={handleChangeModal}
                                            />
                                            <label className="form-check-label" htmlFor={`${estanteria.col}`}>
                                                Columna: {estanteria.col}
                                            </label>
                                        </div>
                                        ))
                                    }
                                </>
                                :
                                <div className="ticket-empty-modal pt-4">
                                    <div><i className="fas fa-box-open fs-20"></i></div>
                                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                                </div>

                            }
                        </div>
                        <div className="modal-footer w-100 p-2 mb-0">
                            {
                                destination !== '' &&
                                <div className="btn btn-primary m-auto" onClick={transfer}>Transferir</div>
                            }
                            
                        </div>
                    </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{zIndex:'1000'}}></div>
            </>
        }
        {
             listBodegas&& listBodegas.length === 0 ?
                <div className="ticket-empty-modal pt-4">
                    <div><i className="fas fa-box-open fs-20"></i></div>
                    <div className="text-empty">En este momento no hay nada para mostrar</div>
                </div>
             :
             <div>
                {
                    listBodegas&& listBodegas.length > 1 ?
                        <Select
                            className='w-100 mb-0'
                            placeholder="Selecciona una bodega"
                            optionFilterProp="children"
                            onChange={(option, children) => handleChangeSelect(option, children)}
                        >
                            { listBodegas &&
                        
                                listBodegas.map((lb, indexlb) => (
                                    <Option name="bodega" key={indexlb + lb.codigo} value={lb.id}>
                                        {lb.nombre}
                                    </Option>
                                ))
                            }
                        </Select>
                        :
                        <div className='text-capitalize'><span className='fw-600'>Bodega:</span> {listBodegas&& listBodegas[0]&& listBodegas[0].nombre.toLowerCase()}</div>
                }
                    {
                        data&& data.bodega !== '' &&
                        <>
                            <div className='my-3 mt-4'>
                            {/*  <Select
                                    placeholder="Tipo"
                                    // defaultValue="estanterias"
                                    style={{ width: '100%' }}
                                    onChange={(options, children)=>{handleChangeSelect(options, children)}}
                                    value={data.type}
                                    options={[
                                        { value: 'estanterias', label: 'Columnas', name:"tipo" },
                                        { value: 'canastas', label: 'Canastas', name:"tipo" },
                                    ]}
                                />*/}
                            <span className='fw-600'>Tipo: Columnas</span>
                        </div> 
                    
                        {/* //////////////// BODY //////////// */}
                        <div className='mt-3'>
                            <input 
                                type="text" 
                                className='form-control form-control-sm input-buscar-listform' 
                                name="buscarpredist" 
                                placeholder="&#xF002;  Buscar" 
                                onChange={(e)=>handlechangeSearch(e, 'ppal')} 
                                value={buscar && buscar.ppal} 
                            />
                        </div>
                        </>
                    }
                    {/* //////////////// LIST DATA////////////////////////// */}
                    <div className='mt-4'>
                        { data.bodega !=='' && 
                            (data.type === 'estanterias' ?
                                (listTYpe && listTYpe.length>0 ?
                                <>
                                    {
                                        listTYpe&& listTYpe.map((estanteria, index)=>(
                                            <div className="form-check my-2" key={index}>
                                                <input 
                                                    className="form-check-input" 
                                                    type="radio" 
                                                    name={estanteria.col} 
                                                    id={estanteria.col}  
                                                    value={estanteria.col} 
                                                    onChange={(e, index)=>handleChange(e, index)}
                                                    checked={estanteria.status}
                                                />
                                                <label className="form-check-label fs-16" htmlFor={estanteria.col} >
                                                    Columna: {estanteria.col}
                                                </label>
                                            </div>
                                            )) 
                                    }
                                </>
                                :  
                                    <div className="ticket-empty-modal pt-4">
                                        <div><i className="fas fa-box-open fs-20"></i></div>
                                        <div className="text-empty">En este momento no hay nada para mostrar</div>
                                    </div>
                                )
                                
                                :
                                <>
                                    <div className='d-flex'>
                                        <div>
                                            radio
                                        </div>
                                        <div className="accordion" id="accordionExample">
                                        {  listTYpe&& listTYpe.length > 0 ? (
                                            listTYpe.map((c, indexc) => (
                                            <div className="card mb-1" key={indexc}>
                                            <div 
                                            className="p-2 border-bottom cursor-pointer fw-600" 
                                            id={`heading${c.can}`}
                                                data-toggle="collapse" 
                                                data-target={`#collapse${c.can}`} 
                                                aria-expanded={indexc === 0 ? 'true' : 'false'} 
                                                aria-controls={`collapse${c.can}`}
                                            >
                                                
                                                Canasta: {c.can}
                                                
                                            </div>
                                            <div id={`collapse${c.can}`} 
                                            className={`collapse ${indexc === 0 ? 'show' : ''}`} 
                                            aria-labelledby={`heading${c.can}`} 
                                            data-parent="#accordionExample">
                                                <div className="card-body p-1">
                                                    <div className="header-canastas">
                                                    {
                                                        c.referencias&& c.referencias.length>0 ?
                                                        <table className="table" key={indexc+'n'}>
                                                        <thead>
                                                            <tr>
                                                            <th className='m-2'>Referencia</th>
                                                            <th className='m-2'>Cant</th>
                                                            <th className='m-2'>
                                                                <input
                                                                    key={'n'}
                                                                    name={`${c.can}-all`}
                                                                    id="all"
                                                                    type="checkbox"
                                                                    className='form-control big-check' 
                                                                    // onChange={(e) => handleCheckboxChange(item, true, null, e.target.checked)}
                                                                    checked={null}
                                                                />
                                                            </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {c&& c.referencias.map((ref, idf) => (
                                                            <tr key={idf}>
                                                                <td>{ref.referencia}</td>
                                                                <td>{ref.cantidad}</td>
                                                                <td>
                                                                    <input 
                                                                        type="checkbox"
                                                                        checked={ref.status}
                                                                        // onChange={(e) => handleCheckboxChange(item, false, idf, e.target.checked)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                        </table>
                                                        :
                                                        <div className="ticket-empty-modal pt-4">
                                                            <div><i className="fas fa-box-open fs-20"></i></div>
                                                            <div className="text-empty">En este momento no hay nada para mostrar</div>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        ))
                                        ) : (
                                            <div className="ticket-empty-modal pt-4">
                                                <div><i className="fas fa-box-open fs-20"></i></div>
                                                <div className="text-empty">En este momento no hay nada para mostrar</div>
                                            </div>
                                        )}
                                    </div>
                                    </div>
                                   
                                </>
                            )
                        
                        }
                    </div>

             </div>
        }
    </div>
  )
}

export default Transfer
